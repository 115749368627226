var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

exports = function requiredIf(type, condition) {
  return function (props) {
    var test = condition(props) ? type.isRequired : type;
    return test.apply(this || _global, arguments);
  };
};

export default exports;